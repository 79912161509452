
.App {
  text-align: center;
}
.lover_left{
    margin-bottom: 5vh;
    margin-left : 2vw;
    position: fixed;
    bottom: 0;
    z-index: 1;
}
.rc-tabs {
  border: none !important;
}
.rc-tabs-tab {
  padding: 15px;
  text-transform: capitalize;
}
.rc-tabs-tab .rc-tabs-tab-btn {
  padding: 5px;
  font-weight: normal;
}
.rc-tabs-tab.rc-tabs-tab-active {
  background: ececec;
  color: #00a6f8;
}
.rc-tabs-ink-bar {
  background: #59c7fd !important;
}
.rc-tabs-content-holder {
  padding: 0px;
  background: #ececec;
}
.rc-tabs-content {
}

pre{
  overflow-x: auto;
  white-space: pre-wrap;
  white-space: -moz-pre-wrap;
  white-space: -pre-wrap;
  white-space: -o-pre-wrap;
  word-wrap: break-word;
  color: black !important; 
  font-family: 'Roboto', sans-serif !important;
}
/* Basics */
html,
body {
  font-family: 'Roboto', sans-serif !important;
  scroll-behavior: smooth;
  width: 100%;
  overflow-x: hidden;
}
.section .card-profile {
  margin-top: 0px;
  margin-bottom: 0px; }

#colorText {
  color: #ff8c00;
}
.colorText2 {
  color: white;
}
.firstDiv {
  display:contents;
  color: rgb(0, 0, 0);
  height: auto;
  width: 100%;
  border-radius: 16px;
  -moz-border-radius: 16px;
  -webkit-border-radius: 30px;
}
.firstDiv2 {
  color: rgb(0, 0, 0);
  height: auto;
  width: 100%;
  border-radius: 10px;
  -moz-border-radius: 10px;
  -webkit-border-radius: 10px;
  margin-top: 10px;
}
.card-heading-shrinked {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}
.card-text-shrinked {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 15;
  -webkit-box-orient: vertical;
}
.pointerfor{
  cursor: pointer;
}
#maxHeight {
  background-repeat: no-repeat;
  background-attachment: apsolute;
  background-position: center;
  background-size: cover;
  width: 100%;
}
#maxHeight2 {
  background-repeat: no-repeat;
  background-attachment: apsolute;
  background-position: center;
  background-size: cover;
  width: 100%;
  overflow-y: scroll;
}
.paddingArticlesCards {
  padding: 50px;
}

#loginForm {
  color: white;
  height: 300px;
  width: 350px;
}
#categoryForm {
  color: white;
  height: 250px;
  width: 380px;
}

.borderRadius {
  border-radius: 30px;
}

.blurred_glass_2 {
  width: 60%;
  margin: 0 auto;
  position: relative;
  background-color: rgba(0, 0, 0, 0.4);
  padding: 15px;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.7);
  animation-duration: 0.5s;
  animation-fill-mode: both;
  animation-delay: 1s;
  color: white;
}

.blurred_glass_3 {
  width: 60%;
  margin: 0 auto;
  position: relative;
  padding: 15px;
  animation-duration: 0.5s;
  animation-fill-mode: both;
  animation-delay: 1s;
  color: white;
}

.blurred_glass {
  width: 100%;
  height: 300px;
  margin: 0 auto;
  position: relative;
  border: 1px solid transparent;
  border-image: linear-gradient(
    90deg,
    rgba(255, 255, 255, 0.9),
    transparent,
    transparent,
    transparent,
    transparent,
    transparent,
    transparent,
    rgba(255, 255, 255, 0.9)
  );
  border-image-slice: 1;
}

.blurred_glass_quick_message {
  width: 30%;
  margin: 0 auto;
  position: relative;
  background-color: rgb(255, 255, 255);
  padding: 20px;
  animation-duration: 0.5s;
  animation-fill-mode: both;
  animation-delay: 1s;
  color: white;
  border-radius: 20px;
}

.blurred_glass_quick_message2 {
  width: 100%;
  margin: 0 auto;
  position: relative;
  border: 3px solid transparent;
}

.blurred_glass_login {
  width: 60%;
  margin: 0 auto;
  position: relative;
  background-color: rgba(0, 0, 0, 0.2);
  padding: 15px;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.7);
  animation-duration: 0.5s;
  animation-fill-mode: both;
  animation-delay: 1s;
  color: white;
}

.blurred_glass_login2 {
  width: 100%;
  margin: 0 auto;
  position: relative;
  border: 3px solid transparent;
  border-image: linear-gradient(
    90deg,
    rgba(255, 255, 255, 0.9),
    transparent,
    transparent,
    transparent,
    transparent,
    transparent,
    transparent,
    rgba(255, 255, 255, 0.9)
  );
  border-image-slice: 1;
}

.blurred_glass_login_buttons {
  width: 30%;
  margin: 0 auto;
  position: relative;
  background-color: rgba(0, 0, 0, 0.2);
  padding: 10px;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.7);
  animation-duration: 0.5s;
  animation-fill-mode: both;
  animation-delay: 1s;
  color: white;
}

#aboutPicture {
  background-color: rgba(0, 0, 0, 0.7);
  color: white !important;
  height: 420px;
  width: 300px;
}
#particlejs {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

#particles-js {
  width: 100%;
  background-repeat: no-repeat;
  background-attachment: apsolute;
  background-position: center;
  background-size: cover;
}

#buttonTransparent {
  background-color: rgba(255, 255, 255, 0.2);
  color: white;
  border: none;
}

#buttonTransparent::placeholder {
  color: white;
  opacity: 1;
}
#buttonTransparent2 {
  background-color: rgba(255, 255, 255, 0.2);
  color: white;
  border: none;
}

.box:hover {
  border-color: white;
  background-color: rgba(255, 255, 255, 0.2);
}
#TitlePage {
  font-size: calc(30px + 2vmin);
  background-color: black;
  border: 1px solid black;
  opacity: 0.4;
  color: white;
  position: fixed;
  top: 0;
  width: 100%;
}

#FooterText {
  font-size: calc(10px + 2vmin);
  background-color: black;
  opacity: 0.8;
  color: white;
  position: fixed;
  bottom: 0;
}

#header {
  background-color: rgba(0, 0, 0, 0.4) !important;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.7) !important;
}
.dropdown-menu {
  background-color: rgba(0, 0, 0, 0.7) !important;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.7) !important;
}

.dropdown-menu-items:hover {
  background: #ff8c00 !important;
  color: black !important;
}

.dropdown-menu-items2:hover {
  color: #ff8c00 !important;
}

.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.center {
  align-items: center;
  justify-content: center;
  display: flex;
}
.App-link {
  color: #61dafb;
}
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.buttonColor {
  color: white;
}
/*ARROW*/
.downArrow {
  top: 35%;
  left: 50%;
}
.naslov-politika {
  color: #ff6600;
  transition: 0.5s;
  -moz-transition: 0.5s;
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
}
.bounce {
  -moz-animation: bounce 3s infinite;
  -webkit-animation: bounce 3s infinite;
  animation: bounce 3s infinite;
}
/*Prekopirano iz CCS2 foldera */
.blurred_glass_2 {
  width: 60%;
  margin: 0 auto;
  position: relative;
  background-color: rgba(0, 0, 0, 0.2);
  padding: 15px;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.7);
  animation-duration: 0.5s;
  animation-fill-mode: both;
  animation-delay: 1s;
  color: white;
}

.blurred_glass {
  width: 100%;
  margin: 0 auto;
  position: relative;
  border: 3px solid transparent;
  border-image: linear-gradient(
    90deg,
    rgba(245, 89, 61, 0.88),
    transparent,
    transparent,
    transparent,
    transparent,
    transparent,
    transparent,
    rgba(245, 89, 61, 0.88)
  );
  border-image-slice: 1;
}

#headerV {
  background-color: rgba(0, 0, 0, 0.4) !important;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.7) !important;
}

#logoVuleVu {
  background: url("https://i.ibb.co/ry5thGX/merlidor.png");
  background-repeat: no-repeat;
  position: absolute;
  width: 120px;
  height: 120px;
}

.topBtn {
  display: none;
  position: fixed;
  bottom: 20px;
  right: 30px;
  z-index: 99;
  border: none;
  outline: none;
  background-color: red;
  color: white;
  cursor: pointer;
  padding: 15px;
  border-radius: 10px;
  font-size: 18px;
}

@-moz-keyframes bounce {
  0%,
  20%,
  50%,
  80%,
  100% {
    -moz-transform: translateY(0);
    transform: translateY(0);
  }
  40% {
    -moz-transform: translateY(-30px);
    transform: translateY(-30px);
  }
  60% {
    -moz-transform: translateY(-15px);
    transform: translateY(-15px);
  }
}
@-webkit-keyframes bounce {
  0%,
  20%,
  50%,
  80%,
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  40% {
    -webkit-transform: translateY(-30px);
    transform: translateY(-30px);
  }
  60% {
    -webkit-transform: translateY(-15px);
    transform: translateY(-15px);
  }
}
@keyframes bounce {
  0%,
  20%,
  50%,
  80%,
  100% {
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  40% {
    -moz-transform: translateY(-30px);
    -ms-transform: translateY(-30px);
    -webkit-transform: translateY(-30px);
    transform: translateY(-30px);
  }
  60% {
    -moz-transform: translateY(-15px);
    -ms-transform: translateY(-15px);
    -webkit-transform: translateY(-15px);
    transform: translateY(-15px);
  }
}
